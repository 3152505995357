<template>
    <div class="px-6 py-4 border border-gray-400 block mb-2">
        <div class="font-bold text-xl">{{ title }}</div>
        <p class="text-xl mb-2">by {{ username }}</p>
        <p class="text-gray-700 text-base">{{ content }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        username: String,
        content: String
    }
}
</script>
