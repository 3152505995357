<template>
    <div>
        <div class="text-center my-5">
            <router-link to="/" class="text-gray-600 block mx-auto">Go back home.</router-link>
        </div>
        <div class="w-full max-w-lg mx-auto">
            <form class="bg-white shadow-lg border border-gray-200 rounded px-8 pt-6 pb-8 mb-4" @submit.prevent="submit">
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="title">
                        Title
                    </label>
                    <input v-model="title" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="title" type="text" placeholder="Title">
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                        Username
                    </label>
                    <input v-model="username" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username">
                </div>
                <div class="mb-6">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="content">
                        Content
                    </label>
                    <textarea v-model="content" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="content" placeholder="Content"/>
                </div>
                <div class="flex items-center justify-between">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
    
</template>

<script>
export default {
    data() {
        return {
            title: '',
            username: '',
            content: ''
        }
    },
    methods: {
        submit() {  
            let payload = `{ "title": "${this.title}", "username": "${this.username}", "content": "${this.content}" }`
            fetch('https://worker.andrew-groebe.workers.dev/', {
                method: 'POST',
                mode: 'no-cors',
                body: payload
            }).then(data => {
                console.log(data)
                this.$router.push('/')
            })
        }
    }
}
</script>
