<template>
    <div>
        <div class="text-center">
            <h1 class="font-bold text-xl">CloudFlare Software Engineering Hiring Challenge</h1>
            <p>by Andrew Groebe</p>
        </div>
        <div class="text-center mt-5">
            <h1 class="font-bold text-xl">Links and Contact Information - Feel free to reach out!</h1>
            <a href="https://agroebe.com" class="underline block">Personal Website</a>
            <a href="https://linkedin.com/in/agroebe" class="underline block">LinkedIn</a>
            <a href="https://github.com/agroebe" class="underline block">GitHub</a>
            <p>Email: agroebe@iastate.edu</p>
        </div>
        <div class="text-center my-5">
            <router-link to="new-post" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer inline-block">
                New Post
            </router-link>
        </div>
        <div class="flex flex-col mx-5" v-if="posts && posts.length > 0">
            <Post v-for="post in posts" :key="post" :title="post.title" :username="post.username" :content="post.content"/>
        </div>
        <div v-else class="text-center">There are no posts - feel free to add one!</div>
    </div>
</template>

<script>
import Post from './Post.vue'

export default {
    components: {
        Post
    },
    mounted() {
        this.getPosts()
    },
    data() {
        return {
            posts: null
        }
    },
    methods: {
        getPosts() {
            fetch('https://worker.andrew-groebe.workers.dev/').then(response => response.json()).then(data => this.posts = data);
        }
    }
}
</script>
